import React from 'react';

export const ParkingIcon: React.FC<React.SVGProps<SVGSVGElement>> = ({
  color = '#F5F6FA',
  ...props
}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.81452 4.23964C8.34938 3.21408 10.1539 2.66669 11.9998 2.66669C14.4752 2.66669 16.8492 3.65002 18.5995 5.40036C20.3498 7.1507 21.3332 9.52467 21.3332 12C21.3332 13.846 20.7858 15.6505 19.7602 17.1853C18.7347 18.7202 17.277 19.9165 15.5716 20.6229C13.8661 21.3293 11.9895 21.5141 10.179 21.154C8.36851 20.7939 6.70547 19.905 5.40018 18.5997C4.09489 17.2944 3.20597 15.6313 2.84585 13.8209C2.48572 12.0104 2.67055 10.1338 3.37697 8.42831C4.08338 6.72286 5.27966 5.2652 6.81452 4.23964ZM8.70037 17.7654V7.18984H13.0024C15.1718 7.18984 16.6668 8.63363 16.6668 10.8103C16.6668 12.9796 15.1205 14.4308 12.8925 14.4308H10.9137V17.7654H8.70037ZM12.4161 8.94144H10.9137V12.7011H12.4088C13.6767 12.7011 14.4169 12.0269 14.4169 10.8176C14.4169 9.61569 13.684 8.94144 12.4161 8.94144Z"
        fill={color}
      />
    </svg>
  );
};
