import React from 'react';

export const AdministrationIcon: React.FC<React.SVGProps<SVGSVGElement>> = ({
  color = '#F5F6FA',
  ...props
}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.89393 2.66699C3.60737 2.67631 3.33236 2.78211 3.11344 2.96725C2.89453 3.15239 2.74454 3.40603 2.68779 3.68706C2.63103 3.9681 2.67083 4.26006 2.80073 4.51565C2.93064 4.77124 3.14304 4.97548 3.40352 5.09527L2.68149 20.9783C2.67942 21.0242 2.68668 21.07 2.7028 21.1129C2.71893 21.1559 2.7436 21.1951 2.77533 21.2283C2.80705 21.2614 2.84516 21.2878 2.88737 21.3058C2.92957 21.3238 2.97499 21.333 3.02087 21.333H4.85438C4.90026 21.333 4.94568 21.3238 4.98788 21.3058C5.03009 21.2878 5.0682 21.2614 5.09993 21.2283C5.13165 21.1951 5.15632 21.1559 5.17245 21.1129C5.18858 21.07 5.19583 21.0242 5.19376 20.9783L4.47258 5.09527C4.73478 4.97435 4.94807 4.76786 5.07742 4.50971C5.20677 4.25157 5.24449 3.95711 5.1844 3.67469C5.1243 3.39227 4.96996 3.13868 4.74671 2.95556C4.52347 2.77245 4.24459 2.67069 3.95587 2.66699H3.89308H3.89393Z"
        fill={color}
      />
      <path
        d="M5.90418 16.0578L14.5457 15.3934H14.7052C14.8053 15.3934 14.8223 15.536 14.7256 15.5606L10.1906 16.6941L10.2279 17.1056C10.2482 17.3275 10.355 17.5325 10.5252 17.6764C10.6953 17.8202 10.9153 17.8913 11.1375 17.8743L21.0203 17.1141C21.1053 17.1075 21.1848 17.0691 21.2428 17.0065C21.3008 16.9439 21.3332 16.8618 21.3333 16.7764V9.76988C21.3333 9.68399 21.3008 9.60129 21.2422 9.53847C21.1836 9.47564 21.1034 9.43736 21.0177 9.43135L13.8992 8.92313L13.7677 7.61141C13.7479 7.41414 13.6597 7.23 13.5184 7.09097C13.377 6.95195 13.1914 6.86681 12.9939 6.85035L5.08203 6.1911L5.90503 16.0586L5.90418 16.0578Z"
        fill={color}
      />
    </svg>
  );
};
