import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  Autocomplete,
  Checkbox,
  Drawer,
  DrawerProps,
  FormControlLabel,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';
import { Form, Formik } from 'formik';
import { Close } from '@mui/icons-material';
import { userStateSelector } from '@app/core/store/user/selectors';
import { Attraction, AttractionDiscount, Discount } from '@app/core/models';
import {
  useCreateAttractionDiscountMutation,
  useGetAttractionDiscountQuery,
  useUpdateAttractionDiscountMutation,
} from '@app/core/store/attraction_discounts';
import {
  useCreateDiscountMutation,
  useUpdateDiscountMutation,
} from '@app/core/store/discounts';
import { LocalInput } from '../LocalInput';
import { LocalButton } from '../LocalButton';
import { useGetAttractionsListQuery } from '@app/core/store/attractions';

interface Props extends DrawerProps {
  mode?: 'view' | 'edit';
  discountId?: AttractionDiscount['id'];
  initialAttractionId?: Attraction['id'];
}

export const DiscountDrawer: React.FC<Props> = ({
  mode,
  discountId,
  onClose,
  initialAttractionId,
  ...props
}) => {
  const { user } = useSelector(userStateSelector);

  const { data: attractionsDataApi } = useGetAttractionsListQuery({});

  const attractions = attractionsDataApi?.data
    ? attractionsDataApi?.data.results
    : [];

  const { data: attractionDiscountData } = useGetAttractionDiscountQuery(
    { id: discountId!, expand: ['discount', 'attraction'] },
    { skip: !discountId },
  );
  const [createDiscountApi, { isError: createDiscountError }] =
    useCreateDiscountMutation();
  const [updateDiscountApi, { isError: updateDiscountError }] =
    useUpdateDiscountMutation();
  const [createAttractionDiscountApi] = useCreateAttractionDiscountMutation();
  const [updateAttractionDiscountApi] = useUpdateAttractionDiscountMutation();

  const attractionDiscountForView = attractionDiscountData?.data
    ? (attractionDiscountData.data as AttractionDiscount)
    : undefined;

  const isCreateMode = !mode && !discountId && !attractionDiscountForView;

  const initialValues =
    !isCreateMode && attractionDiscountForView
      ? attractionDiscountForView
      : // Initial empty strings and zero values to prevent mui null-value console warnings
        new AttractionDiscount({
          promocode: '',
          comment: '',
          discount: new Discount({ currencyValue: 0 }),
          attraction: attractions.find(a => a.id === initialAttractionId),
        });

  const handleSubmitDiscount = async (values: AttractionDiscount) => {
    try {
      if (isCreateMode) {
        const { data } = await createDiscountApi({
          is_percent: values.discount.isPercent,
          percent_value: values.discount.isPercent
            ? values.discount.percentValue
            : null,
          currency_value: !values.discount.isPercent
            ? values.discount.currencyValue
            : null,
        }).unwrap();
        if (data && !createDiscountError) {
          await createAttractionDiscountApi({
            attraction: values.attraction.id,
            discount: data.id,
            comment: values.comment,
            is_by_katadze: user.isStaff,
            promocode: values.promocode,
          }).unwrap();
        }
      } else {
        const { data } = await updateDiscountApi({
          id: attractionDiscountForView!.discountId,
          is_percent: values.discount.isPercent,
          percent_value: values.discount.isPercent
            ? values.discount.percentValue
            : null,
          currency_value: !values.discount.isPercent
            ? values.discount.currencyValue
            : null,
        }).unwrap();
        if (data && !updateDiscountError) {
          await updateAttractionDiscountApi({
            id: attractionDiscountForView!.id,
            attraction: values.attraction.id,
            discount: data.id,
            comment: values.comment,
            is_by_katadze: user.isStaff,
            promocode: values.promocode,
          }).unwrap();
        }
      }
    } catch (error) {
      console.error(error);
      return toast.warn(
        `Произошла ошибка ${
          attractionDiscountForView ? 'обновления' : 'создания'
        } промокода`,
      );
    }

    handleModalClose();
    return toast.success('Промокод успешно обновлен!');
  };

  const handleModalClose = useCallback(
    (e?: React.MouseEvent<HTMLButtonElement>) =>
      onClose && onClose(e ?? {}, 'backdropClick'),
    [onClose],
  );

  if (!isCreateMode && !attractionDiscountForView) return <></>;

  return (
    <Drawer {...props} onClose={onClose} anchor="right">
      <div className="flex items-center justify-between px-5 py-8 border-b border-dark_stroke">
        <span className="text-xl">
          {isCreateMode
            ? 'Новый промокод'
            : `Промокод ${attractionDiscountForView?.promocode}`}
        </span>
        <IconButton
          onClick={handleModalClose}
          className="active:text-yellow_button"
        >
          <Close fontSize="large" color="inherit" />
        </IconButton>
      </div>
      <Formik<AttractionDiscount>
        initialValues={initialValues}
        onSubmit={handleSubmitDiscount}
      >
        {({ handleSubmit, values, handleChange, setFieldValue }) => (
          <Form>
            <div className="flex flex-col items-stretch justify-between max-h-screen px-5 py-8">
              <LocalInput
                name="promocode"
                label="Текст промокода"
                disabled={mode === 'view'}
              />
              <div className="my-7">
                <FormControlLabel
                  name="discount.isPercent"
                  control={
                    <Checkbox
                      checked={values.discount.isPercent}
                      onChange={handleChange}
                    />
                  }
                  label={
                    <span className="font-muller_medium">
                      Скидка в процентах
                    </span>
                  }
                  disabled={mode === 'view'}
                />
              </div>
              <LocalInput
                name={
                  values.discount.isPercent
                    ? 'discount.percentValue'
                    : 'discount.currencyValue'
                }
                label="Размер скидки"
                type="number"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <span className="text-lg">
                        {values.discount.isPercent ? '%' : '₽'}
                      </span>
                    </InputAdornment>
                  ),
                }}
                disabled={mode === 'view'}
              />
              <div className="my-7">
                <span className="text-xl mb-2.5 block">Объект</span>
                <Autocomplete
                  size="small"
                  getOptionLabel={option => option.name}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  value={values.attraction}
                  filterSelectedOptions
                  options={attractions}
                  renderInput={params => (
                    <TextField {...params} name="attraction" size="medium" />
                  )}
                  onChange={(_, v) => setFieldValue('attraction', v)}
                  disabled={mode === 'view'}
                />
              </div>

              <div className="mb-7">
                <LocalInput
                  name="comment"
                  label="Комментарий"
                  disabled={mode === 'view'}
                />
              </div>

              {mode !== 'view' && (
                <div className="justify-self-end self-stretch mt-auto mb-0">
                  <LocalButton
                    type="submit"
                    onClick={() => handleSubmit}
                    className="w-full"
                  >
                    Сохранить
                  </LocalButton>
                </div>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </Drawer>
  );
};
