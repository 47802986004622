import { initAxiosInstance } from '@app/core/services/api';
import { setUserState } from '@app/core/store/user/actions';
import { useRefreshTokenMutation } from '@app/core/store/users';
import { useStorage } from '@app/hooks';
import React, { lazy, Suspense, useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { FullscreenPreloader } from '../Preloaders';
import { PrivateRoute } from '../PrivateRoute';

export enum SYSTEM_ROUTES_ENUM {
  MAIN = '/',
  NOT_FOUND = '/*',
}

export enum UI_ROUTES_ENUM {
  ANALYTICS = '/analytics',
  ATTRACTIONS = '/attractions',
  ATTRACTIONS_WITH_ID = '/attractions/:id',
  NEW_ATTRACTION = '/attractions/new/:categoryParam',
  ROUTES = '/routes',
  ROUTES_WITH_ID = '/routes/:id',
  NEW_ROUTE = '/routes/new',
  DISCOUNTS = '/discounts',
  USERS = '/users',
  USERS_WITH_ID = '/users/:id',
  REVIEWS = '/reviews',
}

type RouteItem<T> = {
  path: T;
  Component: React.FC;
  isProtected?: boolean;
  icon?: React.FC;
};

const ROUTES: RouteItem<SYSTEM_ROUTES_ENUM | UI_ROUTES_ENUM>[] = [
  {
    path: SYSTEM_ROUTES_ENUM.MAIN,
    Component: lazy(() => import('@app/ui/pages/login')),
  },
  {
    path: UI_ROUTES_ENUM.ANALYTICS,
    Component: lazy(() => import('@app/ui/pages/analytics')),
    isProtected: true,
  },
  {
    path: UI_ROUTES_ENUM.ATTRACTIONS,
    Component: lazy(() => import('@app/ui/pages/attractions')),
    isProtected: true,
  },
  {
    path: UI_ROUTES_ENUM.ATTRACTIONS_WITH_ID,
    Component: lazy(() => import('@app/ui/pages/attractions/attraction_view')),
    isProtected: true,
  },
  {
    path: UI_ROUTES_ENUM.NEW_ATTRACTION,
    Component: lazy(() => import('@app/ui/pages/attractions/attraction_new')),
    isProtected: true,
  },
  {
    path: UI_ROUTES_ENUM.ROUTES,
    Component: lazy(() => import('@app/ui/pages/routes')),
    isProtected: true,
  },
  {
    path: UI_ROUTES_ENUM.ROUTES_WITH_ID,
    Component: lazy(() => import('@app/ui/pages/routes/route_view')),
    isProtected: true,
  },
  {
    path: UI_ROUTES_ENUM.NEW_ROUTE,
    Component: lazy(() => import('@app/ui/pages/routes/route_new')),
    isProtected: true,
  },
  {
    path: UI_ROUTES_ENUM.DISCOUNTS,
    Component: lazy(() => import('@app/ui/pages/discounts')),
    isProtected: true,
  },
  {
    path: UI_ROUTES_ENUM.USERS,
    Component: lazy(() => import('@app/ui/pages/users')),
    isProtected: true,
  },
  {
    path: UI_ROUTES_ENUM.USERS_WITH_ID,
    Component: lazy(() => import('@app/ui/pages/users/user_view')),
    isProtected: true,
  },
  {
    path: UI_ROUTES_ENUM.REVIEWS,
    Component: lazy(() => import('@app/ui/pages/reviews')),
    isProtected: true,
  },
  {
    path: SYSTEM_ROUTES_ENUM.NOT_FOUND,
    Component: lazy(() => import('@app/ui/pages/not_found')),
  },
];

export const Router: React.FC = () => {
  const navigate = useNavigate();
  const [refreshTokenApi] = useRefreshTokenMutation();
  const { readStorage, writeStorage, clearStorage } = useStorage();

  const loadApp = async () => {
    const tokenPair = readStorage();
    if (!tokenPair) {
      clearStorage();
      navigate(SYSTEM_ROUTES_ENUM.MAIN);
      return;
    }
    const { refresh: refreshToken } = JSON.parse(tokenPair);
    try {
      const {
        data: { access, refresh },
      } = await refreshTokenApi({ refresh: refreshToken }).unwrap();
      writeStorage(JSON.stringify({ access, refresh }));
      setUserState({ accessToken: access });
      initAxiosInstance();
    } catch (error) {
      console.error(error);
      clearStorage();
      navigate(SYSTEM_ROUTES_ENUM.MAIN);
    }
  };

  useEffect(() => {
    loadApp();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Routes>
      {ROUTES.map(({ isProtected, path, Component }) => (
        <Route
          key={path}
          path={path}
          element={
            <Suspense fallback={<FullscreenPreloader />}>
              {isProtected ? (
                <PrivateRoute>
                  <Component />
                </PrivateRoute>
              ) : (
                <Component />
              )}
            </Suspense>
          }
        />
      ))}
    </Routes>
  );
};
