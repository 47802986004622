import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '@app/core/services/api';
import { ListRequest, RegionCreateRequest } from '@app/core/types/requests';
import { ListResponse, RegionCRUResponse } from '@app/core/types/responses';
import { API_REDUCERS_ENUM } from '@app/core/store/reducers';
import { AxiosResponse } from 'axios';
import { Region } from '@app/core/models';
import { RegionExpand } from '@app/core/models/Region';

export const regionsApi = createApi({
  reducerPath: API_REDUCERS_ENUM.REGIONS,
  baseQuery: axiosBaseQuery(),
  tagTypes: ['Regions'],
  endpoints: build => ({
    getRegionsList: build.query<
      AxiosResponse<ListResponse<Region>>,
      ListRequest<RegionExpand>
    >({
      query: ({ expand, ...params }) => ({
        url: 'regions/',
        method: 'GET',
        params: { expand: expand?.join(','), ...params },
      }),
      transformResponse: (response: AxiosResponse<ListResponse<Region>>) => {
        response.data.results = response.data.results.map(u => new Region(u));
        return response;
      },
      providesTags: res =>
        res?.data.results.length
          ? [
              ...res.data.results.map(
                ({ id }) => ({ type: 'Regions', id } as const),
              ),
            ]
          : [{ type: 'Regions', id: 'LIST' }],
    }),

    createRegion: build.mutation<
      AxiosResponse<RegionCRUResponse>,
      RegionCreateRequest
    >({
      query: data => ({
        url: 'regions/',
        method: 'POST',
        data,
      }),
      transformResponse: (response: AxiosResponse<RegionCRUResponse>) => {
        response.data = new Region(response.data);
        return response;
      },
      invalidatesTags: [{ type: 'Regions', id: 'LIST' }],
    }),
  }),
});

export const {
  useGetRegionsListQuery,
  useLazyGetRegionsListQuery,
  useCreateRegionMutation,
} = regionsApi;

export default regionsApi;
