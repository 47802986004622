import React from 'react';

export const SightIcon: React.FC<React.SVGProps<SVGSVGElement>> = ({
  color = '#F5F6FA',
  ...props
}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.5994 9.72193L5.80086 12.5246C5.6136 12.6151 5.49463 12.8047 5.49463 13.0127V20.2627C5.49463 20.5622 5.73736 20.8049 6.03678 20.8049H9.56079C9.86022 20.8049 10.1029 20.5627 10.1029 20.2633V17.552C10.1029 16.7387 10.374 15.9255 12.0005 15.9255C13.627 15.9255 13.898 16.7387 13.898 17.552V20.2627C13.898 20.5622 14.1408 20.8049 14.4402 20.8049H17.9642C18.2636 20.8049 18.5064 20.5622 18.5064 20.2627V13.0127C18.5064 12.8047 18.3874 12.6151 18.2001 12.5246L12.4016 9.72193C12.1482 9.59947 11.8528 9.59947 11.5994 9.72193Z"
        fill={color}
      />
      <path
        d="M8.47656 7.01303V9.57861C8.47656 9.79068 8.7091 9.92062 8.88971 9.80948L11.7164 8.06996C11.8907 7.96273 12.1105 7.96273 12.2847 8.06996L15.1114 9.80948C15.292 9.92062 15.5246 9.79068 15.5246 9.57861V7.01303C15.5246 6.8826 15.4776 6.75653 15.3921 6.65796L12.4103 3.21736C12.1941 2.96792 11.8071 2.96792 11.5909 3.21736L8.60902 6.65796C8.52359 6.75653 8.47656 6.8826 8.47656 7.01303Z"
        fill={color}
      />
      <path
        d="M19.7422 20.7724V15.9183C19.7422 15.817 19.8578 15.7592 19.9388 15.82L22.1508 17.4789C22.1817 17.5022 22.2 17.5386 22.2 17.5773V20.7724C22.2 20.8402 22.1449 20.8952 22.0771 20.8952H19.8651C19.7972 20.8952 19.7422 20.8402 19.7422 20.7724Z"
        fill={color}
      />
      <path
        d="M4.26465 20.7724V15.9183C4.26465 15.817 4.14904 15.7592 4.06803 15.82L1.85603 17.4789C1.82509 17.5022 1.80688 17.5386 1.80688 17.5773V20.7724C1.80688 20.8402 1.8619 20.8952 1.92977 20.8952H4.14176C4.20963 20.8952 4.26465 20.8402 4.26465 20.7724Z"
        fill={color}
      />
    </svg>
  );
};
