import React from 'react';

export const BankIcon: React.FC<React.SVGProps<SVGSVGElement>> = ({
  color = '#F5F6FA',
  ...props
}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.18502 7.85181H3.70354H20.8147C21.101 7.85181 21.3332 7.61966 21.3332 7.33329V6.40104C21.3332 6.18258 21.1962 5.98755 20.9908 5.91335L12.1759 2.73022C12.0621 2.68912 11.9375 2.68912 11.8237 2.73022L3.00891 5.91335C2.80343 5.98755 2.6665 6.18258 2.6665 6.40104V7.33329C2.6665 7.61966 2.89865 7.85181 3.18502 7.85181Z"
        fill={color}
      />
      <path
        d="M6.45178 9.66663H4.06659C4.00932 9.66663 3.96289 9.71306 3.96289 9.77033V15.0074C3.96289 15.0646 4.00932 15.1111 4.06659 15.1111H6.45178C6.50905 15.1111 6.55548 15.0646 6.55548 15.0074V9.77033C6.55548 9.71306 6.50905 9.66663 6.45178 9.66663Z"
        fill={color}
      />
      <path
        d="M19.9332 9.66663H17.548C17.4908 9.66663 17.4443 9.71306 17.4443 9.77033V15.0074C17.4443 15.0646 17.4908 15.1111 17.548 15.1111H19.9332C19.9905 15.1111 20.0369 15.0646 20.0369 15.0074V9.77033C20.0369 9.71306 19.9905 9.66663 19.9332 9.66663Z"
        fill={color}
      />
      <path
        d="M7.2295 17.9629H3.28876C3.23149 17.9629 3.18506 17.9165 3.18506 17.8592V16.9259C3.18506 16.6395 3.41721 16.4073 3.70358 16.4073H6.81469C7.10106 16.4073 7.33321 16.6395 7.33321 16.9259V17.8592C7.33321 17.9165 7.28678 17.9629 7.2295 17.9629Z"
        fill={color}
      />
      <path
        d="M20.7109 17.9629H16.7702C16.7129 17.9629 16.6665 17.9165 16.6665 17.8592V16.9259C16.6665 16.6395 16.8987 16.4073 17.185 16.4073H20.2961C20.5825 16.4073 20.8147 16.6395 20.8147 16.9259V17.8592C20.8147 17.9165 20.7682 17.9629 20.7109 17.9629Z"
        fill={color}
      />
      <path
        d="M20.448 19.5186H3.55167C3.31691 19.5186 3.09176 19.6118 2.92576 19.7778C2.75976 19.9438 2.6665 20.169 2.6665 20.4037V20.4482C2.6665 20.683 2.75976 20.9081 2.92576 21.0741C3.09176 21.2401 3.31691 21.3334 3.55167 21.3334H20.448C20.6828 21.3334 20.9079 21.2401 21.0739 21.0741C21.2399 20.9081 21.3332 20.683 21.3332 20.4482V20.4037C21.3332 20.169 21.2399 19.9438 21.0739 19.7778C20.9079 19.6118 20.6828 19.5186 20.448 19.5186Z"
        fill={color}
      />
      <path
        d="M11.7832 11.9252H12.6426C13.4873 11.9252 13.9316 12.3451 13.9316 13.0238C13.9316 13.7074 13.4873 14.1273 12.6328 14.1273H11.7832V11.9252ZM13.6924 16.8568V15.9486H11.7832V15.2504H13.0039C14.4443 15.2504 15.4258 14.3129 15.4258 13.0092C15.4258 11.6908 14.4932 10.7533 13.082 10.7533H10.3086V14.1469H9.4248V15.226H10.3086V15.9486H9.4248V16.8568H10.3086V17.7992H11.7832V16.8568H13.6924Z"
        fill={color}
      />
    </svg>
  );
};
