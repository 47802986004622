import { useState, useEffect } from 'react';

export const useProgressiveImage = (source: string): string | null => {
  const [loadedImageSource, setLoadedImageSource] = useState<
    typeof source | null
  >(null);

  useEffect(() => {
    const image = new Image();
    image.src = source;
    image.onload = () => setLoadedImageSource(source);
  }, [source]);

  return loadedImageSource;
};
