import React from 'react';

export const FireIcon: React.FC<React.SVGProps<SVGSVGElement>> = ({
  color = '#F5F6FA',
  ...props
}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.9755 21.1212C15.2027 20.4912 15.1467 19.7679 14.7811 19.0897C14.3798 18.3445 13.8991 17.8872 13.4371 17.4454C12.8164 16.8528 12.2269 16.2896 11.894 15.1012C11.894 15.1012 9.21995 17.1701 8.95395 19.0897C8.85284 19.8099 8.85906 20.5208 9.04729 21.1306C2.71148 20.037 0.939689 14.4572 4.42882 8.87893C4.94215 10.254 5.74949 11.2932 6.85239 11.9963C7.44661 8.30648 9.29929 5.16736 13.5553 2.65668C13.9629 6.22047 15.3878 8.33604 16.6789 10.1934C17.718 9.5556 18.4616 8.65804 18.656 7.32337C22.114 11.1158 22.0565 16.1683 19.4338 18.9901C18.3076 20.2003 16.6711 20.8334 14.9755 21.1212Z"
        fill={color}
      />
    </svg>
  );
};
