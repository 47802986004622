import React from 'react';

export const PoliceIcon: React.FC<React.SVGProps<SVGSVGElement>> = ({
  color = '#F5F6FA',
  ...props
}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.3201 5.95277C19.3201 3.93219 16.0418 2.2923 11.9992 2.2923C7.95654 2.2923 4.67822 3.93219 4.67822 5.95277C4.67822 7.15633 5.83932 8.22372 7.63149 8.89139C7.6148 9.13164 7.6065 9.3724 7.6066 9.61323C7.6066 13.2517 9.573 15.47 11.9992 15.47C14.4253 15.47 16.3917 13.2517 16.3917 9.61323C16.3917 9.37164 16.3829 9.13005 16.3668 8.89139C18.159 8.22226 19.3201 7.15633 19.3201 5.95277ZM13.4633 6.68486C13.4633 7.07319 13.3091 7.44561 13.0345 7.7202C12.7599 7.99478 12.3875 8.14905 11.9992 8.14905C11.6108 8.14905 11.2384 7.99478 10.9638 7.7202C10.6892 7.44561 10.535 7.07319 10.535 6.68486C10.535 6.29653 10.6892 5.92411 10.9638 5.64952C11.2384 5.37493 11.6108 5.22067 11.9992 5.22067C12.3875 5.22067 12.7599 5.37493 13.0345 5.64952C13.3091 5.92411 13.4633 6.29653 13.4633 6.68486Z"
        fill={color}
      />
      <path
        d="M7.61533 15.4865C7.61411 15.4843 7.61245 15.4823 7.61045 15.4807C7.60846 15.4791 7.60616 15.4779 7.6037 15.4773C7.60124 15.4766 7.59867 15.4764 7.59614 15.4767C7.59361 15.4771 7.59117 15.4779 7.58897 15.4792L4.60203 16.9727C4.13907 17.2043 3.76787 17.5855 3.54854 18.0544C3.32921 18.5234 3.27459 19.0526 3.39352 19.5565C3.51245 20.0603 3.79797 20.5093 4.20385 20.8306C4.60973 21.152 5.1122 21.3269 5.62989 21.3271H10.4441C10.4866 21.3271 10.513 21.2832 10.4939 21.2466L7.61533 15.4865Z"
        fill={color}
      />
      <path
        d="M16.4084 15.4786C16.4041 15.4764 16.3991 15.476 16.3945 15.4773C16.3899 15.4787 16.386 15.4818 16.3835 15.4859L13.5034 21.246C13.4991 21.2546 13.4971 21.2642 13.4976 21.2738C13.4981 21.2834 13.5011 21.2927 13.5063 21.3008C13.5114 21.3089 13.5186 21.3155 13.5271 21.32C13.5356 21.3246 13.5451 21.3268 13.5547 21.3265H18.3689C18.8866 21.3263 19.3891 21.1514 19.795 20.83C20.2009 20.5087 20.4864 20.0597 20.6053 19.5559C20.7242 19.052 20.6696 18.5228 20.4503 18.0538C20.231 17.5849 19.8598 17.2037 19.3968 16.972L16.4099 15.4786H16.4084Z"
        fill={color}
      />
    </svg>
  );
};
